<template>
  <div class="login">
    <el-card shadow="never">
      <h2>Авторизация</h2>
      <el-form class="login-form"
               :model="authModel"
               :rules="rules"
               ref="form"
               @submit.native.prevent="login">
        <el-form-item id="app-login" prop="userName">
          <el-input v-model="authModel.userName" placeholder="Логин" prefix-icon="el-icon-user-solid"></el-input>
        </el-form-item>
        <el-form-item id="app-password" prop="password">
          <el-input v-model="authModel.password"
                    placeholder="Пароль"
                    type="password"
                    prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <el-form-item>
          <div>
            <el-button :loading="loading"
                       class="login-button mt-4"
                       type="primary"
                       native-type="submit"
                       block>Войти</el-button>
          </div>
          <!-- <div style="margin-top:5px" v-if="this.serverSpace === 'External'">
            <el-button
                       class="status-button mt-4"
                       block @click="checkRegistrationStatus">Проверить статус регистрации</el-button>
          </div> -->
          <div class="link_action_panel" v-if="this.serverSpace === 'External'">
            <el-link type="primary" :underline="false" class="mt-1"
                     @click="$router.push('/registration')">Зарегистрироваться</el-link>
            <el-link type="primary" :underline="false" class="mt-1" @click="handleForgotPassword()">Забыли пароль?</el-link>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
    <!--    <password-change-modal v-model="dialogVisible"></password-change-modal>-->

    <el-dialog :visible.sync="statusDialogVisible"
               width="40%"
               :close-on-click-modal="false" title="Статус регистрации">
      <div >
        <el-descriptions direction="vertical" :column="1" >
          <el-descriptions-item label="Логин" labelStyle="font-size:12px" contentStyle="font-weight:bold">{{authModel.userName}}</el-descriptions-item>
          <el-descriptions-item label="Статус" labelStyle="font-size:12px;padding-top:15px;" contentStyle="font-weight:bold;color:red">{{registrationStatus}}</el-descriptions-item>
          <el-descriptions-item label="Контакты для связи с администратором системы" labelStyle="font-size:12px;padding-top:15px;" contentStyle="font-weight:bold"><el-link type="primary" :underline="false">{{registrationAdminEmails}}</el-link></el-descriptions-item>
        </el-descriptions>
        <div style="text-align:center">
          <el-button block center class="mt-4"
                      @click="statusDialogVisible=false">Закрыть</el-button>
        </div>
</div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import signalrService from '@/utilities/signalrService';
  import regApi from '@/api/administration/userRegistration';
  import identityApi from '@/api/identity';

  export default {
    name: 'login',
    data() {
      return {
        authModel: {
          userName: '',
          password: '',
          returnUrl: '',
        },
        // dialogVisible: false,
        loading: false,
        disablePasswordValidate: false,
        registrationStatus: '',
        registrationAdminEmails: '',
        statusDialogVisible: false,
        serverSpace: '',
        rules: {
          userName: [
            {
              required: true,
              message: 'Необходимо ввести логин',
              trigger: 'blur',
            },
            {
              min: 4,
              message: 'Логин должен содержать не менее 5 символов',
              trigger: 'blur',
            },
          ],
          password: [
            { validator: this.validatePassword, trigger: 'blur' },
          ],
        },
      };
    },
    computed: {
      ...mapGetters('identity', ['isAuthenticated', 'token', 'userHasRoleGroup']),
    },
    async created() {
      // redirect to home if already logged in
      if (this.isAuthenticated) {
        this.$router.push('/');
      }

      const response = await identityApi.getServerSpace();
      if (response && response.data) {
        this.serverSpace = response.data;
      }
    },
    methods: {
      ...mapActions('identity', [
        'authenticate', 'fetchUser', 'userHasRoleGroup',
      ]),
      ...mapActions('notifications', [
        'getNotifications',
      ]),
      ...mapActions('news', [
        'getUserNews',
      ]),
      ...mapActions('dictionaries', [
        'fetchDictionaries'
      ]),
      async login() {
        this.disablePasswordValidate = false;
        const valid = await this.$refs.form.validate();
        if (!valid) {
          return;
        }

        this.loading = true;
        const isAuth = await this.authenticate(this.authModel);

        if (isAuth && isAuth.data) { // token
          signalrService.connect();

          // получить данные пользоватея
          await this.fetchUser();
          const user = { ...this.$store.getters['identity/user'] };
          const userStatusFirstAuth = user.statusId === 1;
          // получить уведомления для пользователя
          await this.getNotifications();
      await this.$store.dispatch('notifications/loadEventCardCorrelationAsync', this.userHasRoleGroup('ExternalUser'));
          // получить справочники
          await this.fetchDictionaries();

          // получить новости
          await this.getUserNews();
          if (userStatusFirstAuth) {
            await this.$router.push('user-info');
          } else {
            await this.$store.dispatch('notifications/loadEventCardCorrelationAsync',  this.userHasRoleGroup('ExternalUser'));
            if (this.$route.query.returnUrl) {
              await this.$router.push(this.$route.query.returnUrl);
            }
          }
        }

        this.loading = false;
      },
      handleForgotPassword() {
        this.$alert('Обратитесь к ответственному сотруднику диспетчерского центра по вашей операционной зоне.', 'Забыли пароль?', {
          confirmButtonText: 'OK',
          type: 'info',
          customClass: 'alert-30',
        });
      },
      async checkRegistrationStatus() {
        this.disablePasswordValidate = true;
        const valid = await this.$refs.form.validate();
        if (!valid) {
          return;
        }
        const res = await regApi.checkRegistrationStatus(this.authModel.userName);
        if (res.data.succeeded === true) {
          this.registrationStatus = res.data.resultMessage;
          this.registrationAdminEmails = res.data.resultData;
          this.statusDialogVisible = true;
        } else {
          this.$alert(res.data.resultMessage, 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
          });
        }
      },
      validatePassword(rule, value, callback) {
        if (this.disablePasswordValidate) {
          callback();
          return;
        }
        if (!value) {
          callback(new Error('Необходимо ввести пароль'));
        }
        if (value.length < 5) {
          callback(new Error('Пароль должен сождержать не менее 5 символов'));
        }
        callback();
      },
    },
  };
</script>

<style>
  .login .el-form-item:last-of-type {
    margin-bottom: 0px;
  }

  .login .el-card__body {
    padding: 25px;
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h2 {
    margin-top: 0px;
  }

  .login {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-button {
    width: 100%;
  }

  .status-button {
    width: 100%;
  }

  .login-form {
    width: 290px;
  }

  .link_action_panel {
    display: flex;
    justify-content: space-between;
  }
</style>
